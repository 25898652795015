body {
  margin: 0;
  font-family:
    IBM Plex Sans,
    -apple-system,
    BlinkMacSystemFont,
    San Francisco,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    sans-serif; /* TYPEFACE.FONT_FAMILIES.APP_REGULAR */
}
